<template>
  <div>
    <div class="layer-name-no-checkbox">
      <input
        type="radio"
        name="sweep"
        id="swept1"
        value="Swept Streets"
        v-model="showSwept"
      />
      <label class="radioClass" for="swept1"
        ><div class="layer-name">Swept Streets</div></label
      >
    </div>
    <div :class="`${showSwept ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="streets"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">Swept Streets</span>
      </div>
    </div>
    <div :class="`${showSwept ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="dayRoute"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label"> {{ weekDay }} Route Locations </span>
      </div>
    </div>
    <br />
    <div class="layer-name-no-checkbox">
      <input
        type="radio"
        name="sweep"
        id="swept2"
        value="false"
        v-model="showSweptLocations"
      />
      <label class="radioClass" value="All Route Locations" for="swept2"
        ><div class="layer-name">All Route Locations</div></label
      >
    </div>
    <div :class="`${showSweptLocations ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="mon"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">Mon</span>
      </div>
    </div>
    <div :class="`${showSweptLocations ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="tue"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">Tue</span>
      </div>
    </div>
    <div :class="`${showSweptLocations ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="wed"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">Wed</span>
      </div>
    </div>
    <div :class="`${showSweptLocations ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="thu"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">Thu</span>
      </div>
    </div>
    <div :class="`${showSweptLocations ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="multi"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">Multi</span>
      </div>
    </div>
    <br />
    <div class="layer-name-no-checkbox">
      <input
        name="sweep"
        type="radio"
        id="swept3"
        value="2022 Litter Index"
        v-model="showSweptIndex"
      />
      <label class="radioClass" for="swept3"
        ><div class="layer-name">2022 Litter Index</div></label
      >
    </div>
    <div :class="`${showSweptIndex ? 'grid-x wrap' : 'not-show'}`">
      <table class="tabledefault">
        <tbody>
          <tr>
            <td width="35" align="center">
              <div style="width:30px;height:30px;">
                <svg
                  overflow="hidden"
                  width="30"
                  height="30"
                  style="touch-action: none;"
                >
                  <defs></defs>
                  <path
                    fill="rgb(168, 0, 0)"
                    fill-opacity="0.53"
                    stroke="rgb(110, 110, 110)"
                    stroke-opacity="0.53"
                    stroke-width="0.5333333333333333"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="4"
                    path="M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z"
                    d="M-10-10L 10 0L 10 10L-10 10L-10-10Z"
                    fill-rule="evenodd"
                    stroke-dasharray="none"
                    dojoGfxStrokeStyle="solid"
                    transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
                  ></path>
                </svg>
              </div>
            </td>
            <td align="left">
              <span class="legend-label">3.250001 - 4.000000</span>
            </td>
          </tr>
          <tr>
            <td width="35" align="center">
              <div style="width:30px;height:30px;">
                <svg
                  overflow="hidden"
                  width="30"
                  height="30"
                  style="touch-action: none;"
                >
                  <defs></defs>
                  <path
                    fill="rgb(255, 0, 0)"
                    fill-opacity="0.53"
                    stroke="rgb(110, 110, 110)"
                    stroke-opacity="0.53"
                    stroke-width="0.5333333333333333"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="4"
                    path="M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z"
                    d="M-10-10L 10 0L 10 10L-10 10L-10-10Z"
                    fill-rule="evenodd"
                    stroke-dasharray="none"
                    dojoGfxStrokeStyle="solid"
                    transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
                  ></path>
                </svg>
              </div>
            </td>
            <td align="left">
              <span class="legend-label">2.750001 - 3.250000</span>
            </td>
          </tr>
          <tr>
            <td width="35" align="center">
              <div style="width:30px;height:30px;">
                <svg
                  overflow="hidden"
                  width="30"
                  height="30"
                  style="touch-action: none;"
                >
                  <defs></defs>
                  <path
                    fill="rgb(255, 128, 0)"
                    fill-opacity="1"
                    stroke="rgb(110, 110, 110)"
                    stroke-opacity="0.53"
                    stroke-width="0.5333333333333333"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="4"
                    path="M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z"
                    d="M-10-10L 10 0L 10 10L-10 10L-10-10Z"
                    fill-rule="evenodd"
                    stroke-dasharray="none"
                    dojoGfxStrokeStyle="solid"
                    transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
                  ></path>
                </svg>
              </div>
            </td>
            <td align="left">
              <span class="legend-label">2.250001 - 2.750000</span>
            </td>
          </tr>
          <tr>
            <td width="35" align="center">
              <div style="width:30px;height:30px;">
                <svg
                  overflow="hidden"
                  width="30"
                  height="30"
                  style="touch-action: none;"
                >
                  <defs></defs>
                  <path
                    fill="rgb(255, 255, 0)"
                    fill-opacity="0.53"
                    stroke="rgb(110, 110, 110)"
                    stroke-opacity="0.53"
                    stroke-width="0.5333333333333333"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="4"
                    path="M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z"
                    d="M-10-10L 10 0L 10 10L-10 10L-10-10Z"
                    fill-rule="evenodd"
                    stroke-dasharray="none"
                    dojoGfxStrokeStyle="solid"
                    transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
                  ></path>
                </svg>
              </div>
            </td>
            <td align="left">
              <span class="legend-label">1.750001 - 2.250000</span>
            </td>
          </tr>
          <tr>
            <td width="35" align="center">
              <div style="width:30px;height:30px;">
                <svg
                  overflow="hidden"
                  width="30"
                  height="30"
                  style="touch-action: none;"
                >
                  <defs></defs>
                  <path
                    fill="rgb(139, 209, 0)"
                    fill-opacity="0.53"
                    stroke="rgb(110, 110, 110)"
                    stroke-opacity="0.53"
                    stroke-width="0.5333333333333333"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="4"
                    path="M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z"
                    d="M-10-10L 10 0L 10 10L-10 10L-10-10Z"
                    fill-rule="evenodd"
                    stroke-dasharray="none"
                    dojoGfxStrokeStyle="solid"
                    transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
                  ></path>
                </svg>
              </div>
            </td>
            <td align="left">
              <span class="legend-label"> 1.000000 - 1.750000</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSwept: "Swept Streets",
      showSweptLocations: "",
      showSweptIndex: "",
      weekDay: "",
      day: new Date(),
    };
  },
  watch: {
    showSwept(nextVal) {
      // console.log('watch showCollection is firing, nextVal:', nextVal);
      this.setShouldShowSwept(nextVal);
    },
    showSweptLocations(nextVal) {
      // console.log('watch showCollection is firing, nextVal:', nextVal);
      this.setShouldShowAllLocations(nextVal);
    },
    showSweptIndex(nextVal) {
      // console.log('watch showCollection is firing, nextVal:', nextVal);
      this.setShowSweptIndex(nextVal);
    },
  },
  beforeMount() {
    this.getDayOfWeek();
  },
  mounted() {
    this.setShouldShowSwept(this.showSwept);
  },
  methods: {
    setShouldShowSwept(val) {
      let titleAll = "";
      let webMapActiveLayers = this.$store.state.map.webMapActiveLayers;

      if ((this.day.getDay() >= 1) & (this.day.getDay() <= 4)) {
        titleAll = this.weekDay + " Route Locations";
      }

      const searchTitle = ["Swept Streets", titleAll];

      searchTitle.forEach((key) => {
        console.log(key);
        let index = webMapActiveLayers.indexOf(key);

        if (val === "") {
          if (index > -1) {
            webMapActiveLayers.splice(index, 1);
          }
        } else {
          this.showSweptLocations = "";
          this.showSweptIndex = "";
          if (index < 0) {
            webMapActiveLayers.push(key);
          }
        }
      });

      this.$store.commit("setWebMapActiveLayers", webMapActiveLayers);
    },
    setShouldShowAllLocations(val) {
      let webMapActiveLayers = this.$store.state.map.webMapActiveLayers;

      let index = webMapActiveLayers.indexOf("All Route Locations");

      if (val === "") {
        if (index > -1) {
          webMapActiveLayers.splice(index, 1);
        }
      } else {
        this.showSwept = "";
        this.showSweptIndex = "";
        if (index < 0) {
          webMapActiveLayers.push("All Route Locations");
        }
      }
      this.$store.commit("setWebMapActiveLayers", webMapActiveLayers);
    },
    setShowSweptIndex(val) {
      let webMapActiveLayers = this.$store.state.map.webMapActiveLayers;

      let index = webMapActiveLayers.indexOf("2022 Litter Index");

      if (val === "") {
        if (index > -1) {
          webMapActiveLayers.splice(index, 1);
        }
      } else {
        this.showSwept = "";
        this.showSweptLocations = "";
        if (index < 0) {
          webMapActiveLayers.push("2022 Litter Index");
        }
      }
      this.$store.commit("setWebMapActiveLayers", webMapActiveLayers);
    },
    getDayOfWeek() {
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      this.weekDay = weekday[this.day.getDay()];
    },
  },
};
</script>

<style scoped>
.legend-box {
  height: 20px;
  width: 20px;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.not-show {
  display: none;
}

.layer-name-no-checkbox {
  font-weight: normal;
  vertical-align: middle;
  display: inline-block;
  /* margin-left: 30px; */
  margin-bottom: 6px;
}

.legend {
  margin-left: 10px;
}

ul {
  list-style: none;
  margin-left: 0px;
  margin-bottom: 2px;
}

.street-legend {
  border: 1px solid rgb(160, 159, 159);
  padding-left: 5px;
  padding-top: 5px;
}

.street-legend li {
  padding: 5px;
}
.layer-name {
  font-weight: normal;
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
  margin-bottom: 6px;
  font-size: 16px;
}
.main-div-selected {
  padding-bottom: 20px;
}
.radioClass {
  font-size: 16px !important;
  margin: 0 0 0 !important;
}

.legend-label {
  font-size: 13px;
  line-break: auto;
  vertical-align: middle;
  padding: 5px;
}
.dayRoute {
  display: flex;
  flex-direction: row;
  border: 2px solid #ffff9b;
  margin: auto;
  width: 25px;
  border-radius: 1px;
}
.streets {
  display: flex;
  flex-direction: row;
  border: 2px solid #e601a9;
  margin: auto;
  width: 25px;
  border-radius: 1px;
}
.mon {
  display: flex;
  flex-direction: row;
  border: 2px solid #4ce601;
  margin: auto;
  width: 25px;
  border-radius: 1px;
}
.tue {
  display: flex;
  flex-direction: row;
  border: 2px solid #016eff;
  margin: auto;
  width: 25px;
  border-radius: 1px;
}
.wed {
  display: flex;
  flex-direction: row;
  border: 2px solid #ffaa01;
  margin: auto;
  width: 25px;
  border-radius: 1px;
}
.thu {
  display: flex;
  flex-direction: row;
  border: 2px solid #c301ff;
  margin: auto;
  width: 25px;
  border-radius: 1px;
}
.multi {
  display: flex;
  flex-direction: row;
  border: 2px solid #01ffc5;
  margin: auto;
  width: 25px;
  border-radius: 1px;
}
.cell-hr {
  width: 30px;
}
.table-cell {
  vertical-align: middle;
}
.wrap {
  position: relative;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
}
table.tabledefault tbody td {
  border: 1px solid white !important;
  background-color: white !important;
  padding: 1px 1px !important;
}
</style>
