<template>
  <div :class="`${isActive ? 'main' : 'not-show'}`">
    <div class="title">
      <span class="label-title">Treated street status</span>
    </div>
    <div :class="`${shouldShowLegendCityTreated ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="less-six"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">Treated less than 6 hours ago</span>
      </div>
    </div>
    <div :class="`${shouldShowLegendCityTreated ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="six-twelve"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">Treated between 6 and 12 hours ago</span>
      </div>
    </div>
    <div :class="`${shouldShowLegendCityTreated ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="more-twelve"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">Treated over 12 hours ago</span>
      </div>
    </div>
    <div :class="`${shouldShowLegendConditional ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="conditional"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">
          Streets not treated during Conditional Deployment
        </span>
      </div>
    </div>
    <div :class="`${shouldShowLegendHighways ? 'grid-x wrap' : 'not-show'}`">
      <div class="cell-hr">
        <span><hr class="highways"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">
          Streets not treated during Highways Only Deployment
        </span>
      </div>
    </div>
    <div
      :class="`${shouldShowLegendCityNotTreated ? 'grid-x wrap' : 'not-show'}`"
    >
      <div class="cell-hr">
        <span><hr class="not-treated-city"/></span>
      </div>
      <div class="table-cell">
        <span class="legend-label">
          Streets not treated by the City
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import TopicComponent from "@phila/vue-comps/src/components/TopicComponent.vue";

export default {
  name: "Plow-Deployment-Legend",
  mixins: [TopicComponent],
  props: ["deployType", "disableByClassName"],
  mounted() {
    //Hide the form of checkbox-set because we are using other legend component
    let elClass = this.$props.options.disableByClassName;
    if (elClass !== "") {
      let hideElem = document.getElementsByClassName(elClass)[0];
      if (hideElem) {
        hideElem.className = "not-show";
      }
    }
  },
  computed: {
    isActive() {
      let value = false;
      if (this.$props.options) {
        const val = this.evaluateSlot(this.$props.options.deployType) || "";

        if (val !== "" && val !== "There is no active Snow Event.") {
          value = true;
        }
      }

      return value;
    },
    shouldShowLegendHighways() {
      let value = false;
      if (this.$props.options) {
        const val = this.evaluateSlot(this.$props.options.deployType) || "";
        if (val === "Highways Only") {
          value = true;
        }
      }
      return value;
    },
    shouldShowLegendConditional() {
      let value = false;
      if (this.$props.options) {
        const val = this.evaluateSlot(this.$props.options.deployType) || "";
        if (val === "Conditional Deployment") {
          value = true;
        }
      }
      return value;
    },
    shouldShowLegendCityTreated() {
      let value = false;
      if (this.$props.options) {
        const val = this.evaluateSlot(this.$props.options.deployType) || "";
        if (
          val === "Full Deployment" ||
          val === "Conditional Deployment" ||
          val === "Highways Only"
        ) {
          value = true;
        }
      }
      return value;
    },
    shouldShowLegendCityNotTreated() {
      let value = false;
      if (this.$props.options) {
        const val = this.evaluateSlot(this.$props.options.deployType) || "";
        if (
          val === "Full Deployment" ||
          val === "Conditional Deployment" ||
          val === "Highways Only"
        ) {
          value = true;
        }
      }
      return value;
    },
  },
};
</script>

<style scoped>
.main {
  border-style: solid;
  border-width: thin;
  padding: 10px;
}
.wrap {
  position: relative;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
}

.table-cell {
  vertical-align: middle;
}

.cell-hr {
  width: 38px;
}

.label-title {
  font-size: 15px;
  font-family: "Montserrat";
  width: 100%;
}

.title {
  padding-bottom: 5px;
}

.not-show {
  display: none;
}

.legend-label {
  font-size: 12px;
  font-family: "Montserrat";
  line-break: auto;
  vertical-align: middle;
  padding: 5px;
}

.less-six {
  display: flex;
  flex-direction: row;
  border: 3px solid #0f4d90;
  margin: auto;
  width: 30px;
  border-radius: 2px;
}

.six-twelve {
  display: flex;
  flex-direction: row;
  border: 3px dashed #176ac4;
  margin: auto;
  width: 30px;
  border-radius: 2px;
}

.more-twelve {
  display: flex;
  flex-direction: row;
  border: 3px dashed #5c8ef4;
  margin: auto;
  width: 30px;
  border-radius: 2px;
}

.conditional {
  display: flex;
  flex-direction: row;
  border: 1px solid #eb6d00;
  margin: auto;
  width: 30px;
  border-radius: 1px;
}

.highways {
  display: flex;
  flex-direction: row;
  border: 1px solid #eb6d00;
  margin: auto;
  width: 30px;
  border-radius: 1px;
}

.not-treated-city {
  display: flex;
  flex-direction: row;
  border: 1px solid #949494;
  margin: auto;
  width: 30px;
  border-radius: 1px;
}
</style>
