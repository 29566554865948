<template>
  <div>
    <div class="layer-name-no-checkbox">
      <input type="checkbox" id="collectionDay" v-model="showCollection" />
      <label class="checkboxClass" for="collectionDay"
        ><div class="layer-name">Show Collection Day</div></label
      >
    </div>
    <div :class="`${showCollection ? '' : 'not-show'}`">
      <ul>
        <li>
          <div class="grid-x">
            <div class="legend-box monday"></div>
            <div class="legend-label">Monday</div>
          </div>
        </li>
        <li>
          <div class="grid-x">
            <div class="legend-box tuesday"></div>
            <div class="legend-label">Tuesday</div>
          </div>
        </li>
        <li>
          <div class="grid-x">
            <div class="legend-box wednesday"></div>
            <div class="legend-label">Wednesday</div>
          </div>
        </li>
        <li>
          <div class="grid-x">
            <div class="legend-box thursday"></div>
            <div class="legend-label">Thursday</div>
          </div>
        </li>
        <li>
          <div class="grid-x">
            <div class="legend-box friday"></div>
            <div class="legend-label">Friday</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCollection: true,
    };
  },
  watch: {
    showCollection(nextVal) {
      // console.log('watch showCollection is firing, nextVal:', nextVal);
      this.setShouldShowCollection(nextVal);
    },
  },
  mounted() {
    this.$store.commit("setTiledLayers", []);
    this.setShouldShowCollection(this.showCollection);
  },
  destroyed() {
    this.$store.commit("setTiledLayers", []);
  },
  methods: {
    setShouldShowCollection(val) {
      let tiledLayers = this.$store.state.map.tiledLayers;

      let index = tiledLayers.indexOf("collectionDay");
      // console.log('setShouldShowCollection is running, typeof tiledLayer:', typeof tiledLayer, 'val:', val, 'this.$store.state:', this.$store.state, 'this.$store.state.map:', this.$store.state.map, 'this.$store.state.map.tiledLayers:', this.$store.state.map.tiledLayers);
      if (val === false) {
        if (index > -1) {
          tiledLayers.splice(index, 1);
        }
      } else if (val === true) {
        tiledLayers.push("collectionDay");
        console.log(tiledLayers);
      }
      this.$store.commit("setTiledLayers", tiledLayers);
    },
  },
};
</script>

<style>
.legend-box {
  height: 20px;
  width: 20px;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.not-show {
  display: none;
}

.monday {
  background-color: #d7e37d;
}

.tuesday {
  background-color: #2892c7;
}

.wednesday {
  background-color: #8cb8a4;
}

.thursday {
  background-color: #f77a2d;
}

.friday {
  background-color: #fccf51;
}

.legend-label {
  height: 20px;
  width: 30px;
  line-height: 20px;
}

.layer-name-no-checkbox {
  font-weight: normal;
  vertical-align: middle;
  display: inline-block;
  /* margin-left: 30px; */
  margin-bottom: 6px;
}

.legend {
  margin-left: 10px;
}

ul {
  list-style: none;
  margin-left: 0px;
  margin-bottom: 2px;
}

ul ul {
  list-style: none;
  margin-left: 0px;
}

li {
  list-style: none;
}

.street-legend {
  border: 1px solid rgb(160, 159, 159);
  padding-left: 5px;
  padding-top: 5px;
}

.street-legend li {
  padding: 5px;
}
.layer-name {
  font-weight: normal;
  vertical-align: middle;
  display: inline-block;
  margin-left: -6px;
  margin-bottom: 8px;
  font-size: 16px;
}
.main-div-selected {
  padding-bottom: 20px;
}
.checkboxClass {
  font-size: 30px !important;
  margin: 0 0 0 !important;
}
</style>
