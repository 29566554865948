<template>
  <div>
    <div class="flex-div">
      <div class="main-div" :style="minWidth">
        <!-- <div class="maintenance-div">
          <p>Note: This site may down at different points for scheduled maintenance from 8:00 am to 12:00 pm on Sunday, 3/24/2019</p>
        </div>-->
        <div class="top-div small-centered">
          <h2>
            <b></b>
          </h2>
        </div>

        <div class="bottom-div">
          <span class="alert-close-button" @click="close">
            <font-awesome-icon icon="times" class="fa" />
          </span>
          <div class="columns small-24 small-centered medium-24">
            <h1>StreetSmartPHL</h1>
            <p>
              Welcome to StreetSmartPHL. The platform answers the question, “Are
              you Street Smart?” by connecting Philadelphia to near real-time
              information related to permits, paving, snow plowing, and daily
              trash and recycling collections. StreetSmartPHL is the City’s
              first online “smart” tool designed to give residents, visitors and
              businesses direct access to the Streets Department’s core
              services.
            </p>
            <p>We refresh application data hourly.</p>
            <p>
              <input
                type="checkbox"
                id="showAgain"
                :checked="notShowAgain"
                @click="notShowSplashScreen()"
              />
              <label for="showAgain">Do not show again</label>
            </p>
            <a @click="close" class="button uniform-font">Continue To Site</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SplashScreen",
  components: {},
  data() {
    return {
      notShowAgain: localStorage.notShowAgain == "true" ? true : false,
    };
  },
  computed: {
    minWidth() {
      if (this.$store.state.isMobileOrTablet) {
        return;
      } else {
        return { minWidth: "550px" };
      }
    },
  },
  methods: {
    close() {
      this.$store.commit("setPopoverOpen", false);
      this.$store.commit("setPopoverOptions", {});
      this.$store.commit("setPopoverText", "");
    },

    notShowSplashScreen() {
      if (localStorage.notShowAgain == "true") {
        localStorage.notShowAgain = "false";
      } else {
        localStorage.notShowAgain = "true";
      }
    },
  },
};
</script>

<style scoped>
a {
  color: white !important;
  margin: 5px;
}

.button {
  background: #21498b;
}

.uniform-font {
  font-size: 14px;
}
.flex-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.maintenance-div {
  background: #f4eb42;
  color: black;
}
.main-div {
  /* display: flex;
  flex-direction: column; */
  min-height: 600px;
  /* min-width: 500px; */
  max-width: 600px;
  text-align: center;
}
.top-div {
  /* height: 60%; */
  padding: 2px;
  background: #21498b;
}
.bottom-div {
  border-style: solid;
  border-width: 1px;
  border-color: #21498b;
  /* height: 40%; */
  padding: 10px;
  background: white;
  color: black;
}
.disclaimer {
  font-size: 14px;
}

.alert-close-button {
  float: right;
  color: #21498b;
}
</style>
