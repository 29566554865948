export default {
  key: "pickup",
  icon: "trash-alt",
  label: "PickupPHL",
  dataSources: ["notices", "trashDay"],
  tiledLayers: ["collectionDay"],
  components: [
    {
      type: "paragraph",
      slots: {
        text:
          "See where trash and recycling trucks have visited today, and view where trash and recycling are collected each day of the week.\
          ",
      },
    },
    {
      type: "popoverLink",
      options: {
        height: "100%",
        background: "light",
        closeButton: false,
        divStyle: { "padding-bottom": "10px" },
        customStyle: { color: "#21498b", "border-bottom": "0px" },
        components: [
          {
            type: "Disclaimer",
            options: {
              message:
                "<p> \
              Disclaimer: The Streets Department is beta testing GPS technology. \
              Outages and interruptions may occur. The Streets Department will \
              do its best to notify the public if outages occur. \
            </p> \
            <p> \
              Note: This technology processes data every 15 minutes. A visited \
              status can take 15 to 45 minutes to appear on the map. \
            </p>",
            },
          },
        ],
      },
      slots: {
        shouldShowValue: false,
        value: "Read disclaimer",
      },
    },
    {
      type: "trash-status",
      options: {
        trashData: function(state) {
          const data = state.sources.trashDay.data;
          let value;

          if (typeof data !== "undefined") {
            value = data.status;
          } else {
            value = "";
          }

          return value;
        },
      },
    },
    {
      type: "paragraph",
      slots: {
        text: function(state) {
          var msg = "";
          var data = state.sources.notices.data;

          if (data.length > 0) {
            var no_issue = data.filter(
              (e) => e.Type.toLowerCase() == "noissues"
            );

            var data_filter = data.filter(
              (e) => e.Type.toLowerCase() == "pickupphl"
            );

            if (data_filter.length > 0) {
              var itemMsg = "";
              data_filter.forEach(function(item) {
                itemMsg = item.Description;
              });

              msg =
                '<p><i class="fas fa-exclamation-circle fa-1x"></i>   ' +
                itemMsg +
                "</p>";
            } else {
              msg = "";
            }
          }

          return msg;
        },
      },
    },
    {
      type: "checkbox-set",
      options: {
        topicLayers: [
          {
            title: "Sanitation Visits - Close",
            options: {
              layerNameChange: "Status of today’s sanitation truck visits",
              shouldShowCheckbox: false,
              shouldShowSlider: false,
            },
          },
          {
            title: "Sanitation Visits - Intermediate",
            options: {
              layerNameChange: "Status of today’s sanitation truck visits",
              shouldShowCheckbox: false,
              shouldShowSlider: false,
            },
          },
          {
            title: "Sanitation Visits - Far",
            options: {
              layerNameChange: "Status of today’s sanitation truck visits",
              shouldShowCheckbox: false,
              shouldShowSlider: false,
            },
          },
        ],
        defaultTopicLayers: [
          "Sanitation Visits - Close",
          "Sanitation Visits - Intermediate",
          "Sanitation Visits - Far",
        ],
        shouldShowDataLinks: false,
      },
    },
    {
      type: "collection-day-legend",
    },
    /*
    {
      type: "paragraph",
      slots: {
        text:
          "<i>Disclaimer: GPS Technology is being beta-tested by the Streets Department and its the core functionality used for presenting this information. \
          There could be outages and interruptions with this technology. The Streets Department will do their best to notify the public when outages may occur.</i> \
          <br><i>Note: this technology processes the data every 15 minutes. A visited status can take 15 to 45 minutes to appear on the map.</i></br>",
      },
    },
    */
    {
      type: "infoBox",
      slots: {
        icon: "test",
        text:
          '<h3>Additional Information</h3>\
          Visit <a target="blank" href="https://www.phila.gov/trashday/">phila.gov/trashday <i class="fad fa-external-link"></i></a> for service updates.<br>\
          <a target="blank" href="https://www.phila.gov/311/form/Pages/default.aspx">Submit a 311 request <i class="fad fa-external-link"></i></a> to report an issue.',
      },
    },
  ],
};
