<template>
<table class="service-update">
    <tbody>
      <tr :class="`${message ==='Trash and recycling collections are on schedule.' ? 'service-update' : 'service-update--warning'}`">
        <th class="phl-mu icon-only">
          <i class="fa-1x fa-fw fas fa-trash-alt service-icon " aria-hidden="true"></i>
        </th>
        <td class="pam">
          <span class="bold">
            {{message}}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import TopicComponent from "@phila/vue-comps/src/components/TopicComponent.vue";

export default {
  name: "Trash-Status",
  mixins: [TopicComponent],
  props: ["trashData"],
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {},
  computed: {
    message() {
      if (this.$props.options) {
        return this.evaluateSlot(this.$props.options.trashData) || "";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.service-update .service-update--warning td {
    background: rgba(255,239,162,0.5);
}
.service-update td {
    background: rgba(185,242,177,0.5);
}
.service-update th, .service-update td {
    border-bottom: 1rem solid white;
    border-right: .5rem solid white;
}
.pam {
    padding: 1rem;
}
.service-update {
    font-family: 'Montserrat', 'Tahoma', sans-serif;
    font-weight: 100;
    margin-bottom: .5rem;
    color: #444;
    text-align: left;
}
.service-update .icon-only {
    width: 1%;
    padding: 1rem;
}
</style>