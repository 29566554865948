export default {
  key: "sweep",
  icon: "broom",
  label: "SweepPHL",
  //disabled: storm !== undefined ? null : true,
  dataSources: ["notices"],
  components: [
    {
      type: "paragraph",
      slots: {
        text: "View status of Mechanical Street Sweep Operations.",
      },
    },
    {
      type: "popoverLink",
      options: {
        height: "100%",
        background: "light",
        closeButton: false,
        divStyle: { "padding-bottom": "10px" },
        customStyle: { color: "#21498b", "border-bottom": "0px" },
        components: [
          {
            type: "Disclaimer",
            options: {
              message:
                "<p> \
              Disclaimer: The Streets Department is beta testing GPS technology. \
              Outages and interruptions may occur. The Streets Department will \
              do its best to notify the public if outages occur. \
            </p> \
            <p> \
              Note: This technology processes data every 15 minutes. A treated street\
              status can take 15 to 45 minutes to appear on the map. \
            </p>",
            },
          },
        ],
      },
      slots: {
        shouldShowValue: false,
        value: "Read disclaimer",
      },
    },
    {
      type: "paragraph",
      slots: {
        text: function(state) {
          var msg = "";
          var data = state.sources.notices.data;

          if (data.length > 0) {
            var no_issue = data.filter(
              (e) => e.Type.toLowerCase() == "noissues"
            );

            var data_filter = data.filter(
              (e) => e.Type.toLowerCase() == "sweepphl"
            );

            if (data_filter.length > 0) {
              var itemMsg = "";
              data_filter.forEach(function(item) {
                itemMsg = item.Description;
              });

              msg =
                '<p><i class="fas fa-exclamation-circle fa-1x"></i>   ' +
                itemMsg +
                "</p>";
            } else {
              msg = "";
            }
          }
          return msg;
        },
      },
    },
    {
      type: "sweep-legend",
    },
    {
      type: "checkbox-set",
      options: {
        topicLayers: [
          {
            title: "All Route Locations",
            options: {
              shouldShowSlider: false,
              layerNameChange: "   ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: "Swept Streets",
            options: {
              shouldShowSlider: false,
              layerNameChange: " ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: function(state) {
              var d = new Date();
              var weekday = new Array(7);
              weekday[0] = "Sunday";
              weekday[1] = "Monday";
              weekday[2] = "Tuesday";
              weekday[3] = "Wednesday";
              weekday[4] = "Thursday";
              weekday[5] = "Friday";
              weekday[6] = "Saturday";

              var nDay = weekday[d.getDay()];

              let title = "";

              if ((d.getDay() >= 1) & (d.getDay() <= 4)) {
                title = nDay + " Route Locations";
              }

              return title;
            },
            options: {
              shouldShowSlider: false,
              layerNameChange: "  ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: "2022 Litter Index",
            options: {
              shouldShowSlider: false,
              layerNameChange: "    ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
        ],
        defaultTopicLayers: [],
        shouldShowDataLinks: false,
        hideDisabledCheckboxes: false,
      },
    },
  ],
};
