export default {
  key: "snow",
  icon: "snowflake",
  label: "PlowPHL",
  //disabled: storm !== undefined ? null : true,
  dataSources: ["storms", "notices"],
  tiledLayers: [
    "plowTreatedStreetsStatus",
    "plowNotTreatedStreets",
    "plowConditional",
    "plowHighways",
  ],
  components: [
    {
      type: "paragraph",
      slots: {
        text:
          "View status of salting and plowing operations during a winter weather event.",
      },
    },
    {
      type: "popoverLink",
      options: {
        height: "100%",
        background: "light",
        closeButton: false,
        divStyle: { "padding-bottom": "10px" },
        customStyle: { color: "#21498b", "border-bottom": "0px" },
        components: [
          {
            type: "Disclaimer",
            options: {
              message:
                "<p> \
              Disclaimer: The Streets Department is beta testing GPS technology. \
              Outages and interruptions may occur. The Streets Department will \
              do its best to notify the public if outages occur. \
            </p> \
            <p> \
              Note: This technology processes data every 15 minutes. A treated street\
              status can take 15 to 45 minutes to appear on the map. \
            </p>",
            },
          },
        ],
      },
      slots: {
        shouldShowValue: false,
        value: "Read disclaimer",
      },
    },
    {
      type: "paragraph",
      slots: {
        text: function(state) {
          var msg = "";
          var data = state.sources.notices.data;

          if (data.length > 0) {
            var no_issue = data.filter(
              (e) => e.Type.toLowerCase() == "noissues"
            );

            var data_filter = data.filter(
              (e) => e.Type.toLowerCase() == "plowphl"
            );

            if (data_filter.length > 0) {
              var itemMsg = "";
              data_filter.forEach(function(item) {
                itemMsg = item.Description;
              });

              msg =
                '<p><i class="fas fa-exclamation-circle fa-1x"></i>   ' +
                itemMsg +
                "</p>";
            } else {
              msg = "";
            }
          }
          return msg;
        },
      },
    },
    {
      type: "deployment-type",
      options: {
        deployType: function(state) {
          const data = state.sources.storms.data;
          let value;

          if (data.Deployment === "") {
            value = "There is no active Snow Event. ";
          } else {
            value =
              '<a target="_blank" href="https://www.phila.gov/departments/department-of-streets/roadways/snow-events/#salting-and-plowing-operations">' +
              data.Deployment +
              "  </a>";
          }

          return value;
        },
      },
    },
    {
      type: "plow-deployment-legend",
      options: {
        deployType: function(state) {
          const data = state.sources.storms.data;
          let value;

          if (data.Deployment === "") {
            value = "There is no active Snow Event.";
          } else {
            value = data.Deployment;
          }

          return value;
        },
        disableByClassName: "",
      },
    },
    {
      type: "checkbox-set",
      options: {
        topicLayers: [
          {
            title: function(state) {
              const data = state.sources.storms.data;
              let title = "";

              if (
                data.Deployment === "Full Deployment" ||
                data.Deployment === "Conditional Deployment" ||
                data.Deployment === "Highways Only"
              ) {
                title = "Treated Street Status";
              }

              return title;
            },
            options: {
              shouldShowSlider: false,
              layerNameChange: " ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: function(state) {
              const data = state.sources.storms.data;
              let title = "";

              if (
                data.Deployment === "Full Deployment" ||
                data.Deployment === "Conditional Deployment" ||
                data.Deployment === "Highways Only"
              ) {
                title = "Treated Street Status (Zoomed Out Labels)";
              }

              return title;
            },
            options: {
              shouldShowSlider: false,
              layerNameChange: " ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: function(state) {
              const data = state.sources.storms.data;
              let title = "";

              if (data) {
                if (data.Deployment === "Conditional Deployment") {
                  title = "Streets not treated during a Conditional Deployment";
                }
              }

              return title;
            },
            options: {
              shouldShowSlider: false,
              layerNameChange: "  ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: function(state) {
              const data = state.sources.storms.data;
              let title = "";

              if (data) {
                if (data.Deployment === "Conditional Deployment") {
                  title =
                    "Streets not treated during a Conditional Deployment (Zoomed Out Labels)";
                }
              }

              return title;
            },
            options: {
              shouldShowSlider: false,
              layerNameChange: "  ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: function(state) {
              const data = state.sources.storms.data;
              let title = "";

              if (data) {
                if (data.Deployment === "Highways Only") {
                  title = "Streets not treated by a Highways Only Deployment";
                }
              }

              return title;
            },
            options: {
              shouldShowSlider: false,
              layerNameChange: "   ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: function(state) {
              const data = state.sources.storms.data;
              let title = "";

              if (data) {
                if (data.Deployment === "Highways Only") {
                  title =
                    "Streets not treated by a Highways Only Deployment (Zoomed Out Labels)";
                }
              }

              return title;
            },
            options: {
              shouldShowSlider: false,
              layerNameChange: "   ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: function(state) {
              const data = state.sources.storms.data;
              let title = "";

              if (
                data.Deployment === "Full Deployment" ||
                data.Deployment === "Conditional Deployment" ||
                data.Deployment === "Highways Only"
              ) {
                title = "Streets not treated by the City";
              }

              return title;
            },
            options: {
              shouldShowSlider: false,
              layerNameChange: "    ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
          {
            title: function(state) {
              const data = state.sources.storms.data;
              let title = "";

              if (
                data.Deployment === "Full Deployment" ||
                data.Deployment === "Conditional Deployment" ||
                data.Deployment === "Highways Only"
              ) {
                title = "Streets not treated by the City (Zoomed Out Labels)";
              }

              return title;
            },
            options: {
              shouldShowSlider: false,
              layerNameChange: "    ",
              shouldShowCheckbox: false,
              shouldShowLegendBox: false,
            },
          },
        ],
        defaultTopicLayers: [
          "Treated Street Status",
          "Treated Street Status (Zoomed Out Labels)",
          "Streets not treated during a Conditional Deployment",
          "Streets not treated during a Conditional Deployment (Zoomed Out Labels)",
          "Streets not treated by a Highways Only Deployment",
          "Streets not treated by a Highways Only Deployment (Zoomed Out Labels)",
          "Streets not treated by the City",
          "Streets not treated by the City (Zoomed Out Labels)",
        ],
        shouldShowDataLinks: false,
        hideDisabledCheckboxes: true,
      },
    },
    {
      type: "topic-tiled-layers",
      options: {
        tiledLayerName: "plowTreatedStreetsStatus",
        layersObj: {
          zoomIn: { title: "Treated Street Status", tiled: false },
          zoomOut: {
            title: "Treated Street Status (Zoomed Out Labels)",
            tiled: true,
          },
        },
        shouldShowTile: function(state) {
          const data = state.sources.storms.data;
          let value = false;

          if (
            data.Deployment === "Full Deployment" ||
            data.Deployment === "Conditional Deployment" ||
            data.Deployment === "Highways Only"
          ) {
            value = true;
          }

          return value;
        },
      },
    },
    {
      type: "topic-tiled-layers",
      options: {
        tiledLayerName: "plowNotTreatedStreets",
        layersObj: {
          zoomIn: { title: "Streets not treated by the City", tiled: false },
          zoomOut: {
            title: "Streets not treated by the City (Zoomed Out Labels)",
            tiled: true,
          },
        },
        shouldShowTile: function(state) {
          const data = state.sources.storms.data;
          let value = false;

          if (
            data.Deployment === "Full Deployment" ||
            data.Deployment === "Conditional Deployment" ||
            data.Deployment === "Highways Only"
          ) {
            value = true;
          }

          return value;
        },
      },
    },
    {
      type: "topic-tiled-layers",
      options: {
        tiledLayerName: "plowConditional",
        layersObj: {
          zoomIn: {
            title: "Streets not treated during a Conditional Deployment",
            tiled: false,
          },
          zoomOut: {
            title:
              "Streets not treated during a Conditional Deployment (Zoomed Out Labels)",
            tiled: true,
          },
        },
        shouldShowTile: function(state) {
          const data = state.sources.storms.data;
          let value = false;

          if (data.Deployment === "Conditional Deployment") {
            value = true;
          }

          return value;
        },
      },
    },
    {
      type: "topic-tiled-layers",
      options: {
        tiledLayerName: "plowHighways",
        layersObj: {
          zoomIn: {
            title: "Streets not treated by a Highways Only Deployment",
            tiled: false,
          },
          zoomOut: {
            title:
              "Streets not treated by a Highways Only Deployment (Zoomed Out Labels)",
            tiled: true,
          },
        },
        shouldShowTile: function(state) {
          const data = state.sources.storms.data;
          let value = false;

          if (data.Deployment === "Highways Only") {
            value = true;
          }

          return value;
        },
      },
    },
    {
      type: "infoBox",
      slots: {
        icon: "test",
        text:
          '<h3>Additional Information</h3>\
          Visit <a target="blank" href="https://www.phila.gov/departments/department-of-streets/roadways/snow-events/">phila.gov <i class="fad fa-external-link"></i></a> to learn more snow detail.<br>',
      },
    },
  ],
};
