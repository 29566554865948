export default {
  key: "permit",
  icon: "scroll",
  label: "PermitPHL",
  dataSources: ["notices"],
  components: [
    {
      type: "paragraph",
      slots: {
        text: "View street and sidewalk closure permits.",
      },
    },
    {
      type: "paragraph",
      slots: {
        text: function(state) {
          var msg = "";
          var data = state.sources.notices.data;

          if (data.length > 0) {
            var no_issue = data.filter(
              (e) => e.Type.toLowerCase() == "noissues"
            );

            var data_filter = data.filter(
              (e) => e.Type.toLowerCase() == "permitphl"
            );

            if (data_filter.length > 0) {
              var itemMsg = "";
              data_filter.forEach(function(item) {
                itemMsg = item.Description;
              });

              msg =
                '<p><i class="fas fa-exclamation-circle fa-1x"></i>   ' +
                itemMsg +
                "</p>";
            } else {
              msg = "";
            }
          }

          return msg;
        },
      },
    },
    {
      type: "checkbox-set",
      options: {
        topicLayers: [
          {
            title: "Current Closures (points)",
            options: {
              shouldShowSlider: false,
            },
          },
          {
            title: "Current Closures (segments)",
            options: {
              shouldShowSlider: false,
            },
          },
          {
            title: "Future Closures (points)",
            options: {
              shouldShowSlider: false,
            },
          },
          {
            title: "Future Closures (segments)",
            options: {
              shouldShowSlider: false,
            },
          },
        ],
        defaultTopicLayers: [
          "Current Closures (points)",
          "Current Closures (segments)",
        ],
        shouldShowDataLinks: false,
      },
    },
    {
      type: "infoBox",
      slots: {
        icon: "test",
        text:
          '<h3>Additional Information</h3>\
          To apply for permit, visit <a target="blank" href="https://stsweb.phila.gov/streetclosure/">streetclosure <i class="fad fa-external-link"></i></a><br>\
          To report a violation <a target="blank" href="http://iframe.publicstuff.com/#/?client_id=242&request_type_id=1012280">submit a 311 request <i class="fad fa-external-link"></i></a><br>\
          Download FAQ doc <a target="blank" href="http://stsweb.phila.gov/permitPHL/FAQ.pdf">here <i class="fad fa-external-link"></i></a>',
      },
    },
  ],
};
