var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_vm._m(0),_c('div',{staticClass:"grid-x align-center-middle"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"check-square","aria-hidden":"true"}})],1),_vm._m(3),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":['far', 'circle'],"aria-hidden":"true"}})],1),_vm._m(4),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":['far', 'square'],"aria-hidden":"true"}})],1),_vm._m(5),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"map-marker-alt","aria-hidden":"true"}})],1),_vm._m(6),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"search","aria-hidden":"true"}})],1),_vm._m(7),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('img',{staticClass:"street-view-image",attrs:{"src":'images/cyclomedia_blue.png'}})]),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"text-center"},[_c('b',[_vm._v("How to use StreetSmartPHL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('div',{staticClass:"topic"},[_vm._v("PermitPHL")]),_c('div',{staticClass:"topic"},[_vm._v("PavePHL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Open topics on the left to expose controls for turning on and off map layers.")])]),_c('p',[_vm._v("You can only open one topic at a time.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("If a topic offers checkboxes, toggle map layers on and off with them.")])]),_c('p',[_vm._v("You can toggle on as many layers with checkboxes as you want, and they will all show on the map.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("If a topic offers radio buttons, select one to view a single map layer.")])]),_c('p',[_vm._v("You can only view one layer at a time if a topic uses radio buttons.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("If a checkbox is grayed out, zoom in further on the map and it will become active.")])]),_c('p',[_vm._v("Many of the layers will not show when the map is zoomed out too far.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Click on any feature that appears on the map to get a popup with that feature's info.")])]),_c('p',[_vm._v("If you have turned on overlapping layers, you will be able to click through multiple popups of info.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Search for an address or intersection.")])]),_c('p',[_vm._v("The map will move to the location that you have searched.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Toggle on and off street view.")])]),_c('p',[_vm._v("Half of the map panel will be taken up by the street view. If you are zoomed in far enough, circles will appear along the streets in the map. Click on a circle to change the street view location to that point.")])])
}]

export { render, staticRenderFns }