<template>
  <div>
    <div class="flex-div">
      <div class="main-div" :style="minWidth">
        <!-- <div class="maintenance-div">
          <p>Note: This site may down at different points for scheduled maintenance from 8:00 am to 12:00 pm on Sunday, 3/24/2019</p>
        </div>-->
        <div class="top-div small-centered">
          <h2>
            <b></b>
          </h2>
        </div>

        <div class="bottom-div">
          <span class="alert-close-button" @click="close">
            <font-awesome-icon icon="times" class="fa" />
          </span>
          <div class="columns small-24 small-centered medium-24">
            <span v-html="this.$props.options.message"></span>
            <a @click="close" class="button uniform-font">Return To Site</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopicComponent from "@phila/vue-comps/src/components/TopicComponent.vue";

export default {
  name: "Disclaimer",
  mixins: [TopicComponent],
  props: ["message"],
  components: {},
  data() {
    return {
      notShowAgain: localStorage.notShowAgain == "true" ? true : false,
    };
  },
  computed: {
    minWidth() {
      if (this.$store.state.isMobileOrTablet) {
        return;
      } else {
        return { minWidth: "550px" };
      }
    },
  },
  methods: {
    close() {
      this.$store.commit("setPopoverOpen", false);
      this.$store.commit("setPopoverOptions", {});
      this.$store.commit("setPopoverText", "");
    },
  },
};
</script>

<style scoped>
a {
  color: white !important;
  margin: 5px;
}

.button {
  background: #21498b;
}

.uniform-font {
  font-size: 14px;
}

.flex-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.maintenance-div {
  background: #f4eb42;
  color: black;
}
.main-div {
  /* display: flex;
  flex-direction: column; */
  min-height: 600px;
  /* min-width: 500px; */
  max-width: 600px;
  text-align: center;
}
.top-div {
  /* height: 60%; */
  padding: 2px;
  background: #21498b;
}
.bottom-div {
  border-style: solid;
  border-width: 1px;
  border-color: #21498b;
  /* height: 40%; */
  padding: 10px;
  background: white;
  color: black;
}
.disclaimer {
  font-size: 14px;
}

.alert-close-button {
  float: right;
  color: #21498b;
}
</style>
