<template> </template>
<script>
import TopicComponent from "@phila/vue-comps/src/components/TopicComponent.vue";

export default {
  name: "Topic-Tiled-Layer",
  mixins: [TopicComponent],
  props: ["tiledLayerName", "layersObj", "shouldShowTile"],
  data() {
    return {
      tiledLayer: this.$props.options.tiledLayerName,
      layers: this.$props.options.layersObj,
      shouldShowTileLayer: this.evaluateSlot(
        this.$props.options.shouldShowTile
      ),
    };
  },
  watch: {
    showTiledLayers(val) {
      if (this.shouldShowTileLayer) {
        //this.setShowTiledLayers(val);
      }
    },
  },
  mounted() {
    if (this.shouldShowTileLayer) {
      this.defaultLayer();
      this.setShowTiledLayers(this.shouldShowTileLayer);
    }
  },
  destroyed() {
    //this.$store.commit("setTiledLayers", []);
  },
  methods: {
    setShowTiledLayers(val) {
      let tiledLayers = this.$store.state.map.tiledLayers;
      // console.log('setTiledLayers is running, typeof tiledLayer:', typeof tiledLayer, 'val:', val, 'this.$store.state:', this.$store.state, 'this.$store.state.map:', this.$store.state.map, 'this.$store.state.map.tiledLayers:', this.$store.state.map.tiledLayers);
      let index = tiledLayers.indexOf(this.tiledLayer);
      console.log(tiledLayers);
      if (val === false) {
        if (index > -1) {
          tiledLayers.splice(index, 1);
        }
      } else if (val === true && index === -1) {
        //tiledLayers = [];
        tiledLayers.push(this.tiledLayer);
      }

      this.$store.commit("setTiledLayers", tiledLayers);
      //console.log("pushed: ", this.$store.state.map.tiledLayers);
    },
    defaultLayer() {
      let defaultLayers = this.$store.state.layers.defaultLayers;
      let displayedLayers = this.$store.state.map.webMapDisplayedLayers;
      Object.keys(this.layers).forEach((key) => {
        if (
          defaultLayers.indexOf(this.layers[key].title) > -1 &&
          !this.isLayerDisabled(this.layers[key].title)
        ) {
          if (displayedLayers.indexOf(this.layers[key].title) === -1) {
            displayedLayers.push(this.layers[key].title);
          }
        }
      });

      this.$store.commit("setWebMapDisplayedLayers", displayedLayers);
    },
    layerDefinition(layerTitle) {
      let layer = this.$store.state.map.webMapLayersAndRest.find(
        (x) => x.title === layerTitle
      );

      return layer.rest.layerDefinition;
    },
    isLayerDisabled(layerTitle) {
      let value = false;
      const def = this.layerDefinition(layerTitle);
      //console.log('shouldBeDisabled is running, layerName:', layerTitle, 'def.minScale:', def.minScale, 'def.maxScale:', def.maxScale, 'this.scale:', this.scale);
      if (def) {
        if (def.minScale) {
          // console.log('shouldBeDisabled is running, if def.minScale, this.$props.layerName:', this.$props.layerName, 'def:', def);
          if (this.scale < def.minScale && this.scale > def.maxScale) {
            // console.log('shouldBeDisabled is running, first if, this.$props.layerName:', this.$props.layerName, 'def.minScale:', def.minScale, 'def.maxScale:', def.maxScale, 'this.scale:', this.scale);
            value = false;
            // return true;
          } else {
            // console.log('shouldBeDisabled is running, first else, this.$props.layerName:', this.$props.layerName, 'def.minScale:', def.minScale, 'def.maxScale:', def.maxScale, 'this.scale:', this.scale);
            value = true;
          }
        } else if (def.maxScale) {
          // console.log('shouldBeDisabled is running, if def.maxScale, this.$props.layerName:', this.$props.layerName, 'def:', def);
          if (this.scale > def.maxScale) {
            // console.log('shouldBeDisabled is running, first if, this.$props.layerName:', this.$props.layerName, 'def.minScale:', def.minScale, 'def.maxScale:', def.maxScale, 'this.scale:', this.scale);
            value = false;
            // return true;
          } else {
            // console.log('shouldBeDisabled is running, first else, this.$props.layerName:', this.$props.layerName, 'def.minScale:', def.minScale, 'def.maxScale:', def.maxScale, 'this.scale:', this.scale);
            value = true;
          }
        }
        // console.log('shouldBeDisabled is running, lost zone, this.$props.layerName:', this.$props.layerName, 'def.minScale:', def.minScale, 'def.maxScale:', def.maxScale, 'this.scale:', this.scale);
      } else {
        // console.log('shouldBeDisabled is running, 2nd else, this.$props.layerName:', this.$props.layerName, 'def.minScale:', def.minScale, 'def.maxScale:', def.maxScale, 'this.scale:', this.scale);
        value = false;
        // return false;
      }
      // console.log('shouldBeDisabled is running, value:', value, 'this.$props.layerName:', this.$props.layerName, 'def.minScale:', def.minScale, 'def.maxScale:', def.maxScale, 'this.scale:', this.scale);
      return value;
    },
  },
  computed: {
    showTiledLayers() {
      let displayedLayers = this.$store.state.map.webMapDisplayedLayers;
      let value = false;

      Object.keys(this.layers).forEach((key) => {
        if (
          displayedLayers.indexOf(this.layers[key].title) > -1 &&
          this.layers[key].tiled
        ) {
          value = true;

          //console.log("pushed: ", this.layers[key].title, value);
        }
      });

      return value;
    },
    scale() {
      return this.$store.state.map.scale;
    },
  },
};
</script>

<style scoped></style>
