/*
  _________ __                         __   _________                      __
/   _____//  |________   ____   _____/  |_/   _____/ _____ _____ ________/  |_
\_____  \\   __\_  __ \_/ __ \_/ __ \   __\_____  \ /     \\__  \\_  __ \   __\
/        \|  |  |  | \/\  ___/\  ___/|  | /        \  Y Y  \/ __ \|  | \/|  |
/_______  /|__|  |__|    \___  >\___  >__|/_______  /__|_|  (____  /__|   |__|
      \/                   \/     \/            \/      \/     \/
*/

// turn off console logging in production
const { hostname = "" } = location;
if (hostname !== "localhost" && !hostname.match(/(\d+\.){3}\d+/)) {
  console.log = console.info = console.debug = console.error = function () {};
}

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons/faSnowflake";
import { faScroll } from "@fortawesome/free-solid-svg-icons/faScroll";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faRoad } from "@fortawesome/free-solid-svg-icons/faRoad";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faBroom } from "@fortawesome/free-solid-svg-icons/faBroom";
library.add(
  faSnowflake,
  faScroll,
  faTrashAlt,
  faRoad,
  faInfoCircle,
  faExclamationCircle,
  faBroom
);

import layerboard from "@phila/layerboard/src/main.js";

// data-sources
import weekPave from "./data-sources/week-pave";
import weekMill from "./data-sources/week-mill";
import notices from "./data-sources/notices";
import storms from "./data-sources/storms";
import trashDay from "./data-sources/trashday";

// Topics
import pave from "./topics/pave";
import plow from "./topics/plow";
import pickup from "./topics/pickup";
import permit from "./topics/permit";
import sweep from "./topics/sweep";

import instructions from "./components/Instructions.vue";
import splashScreen from "./components/SplashScreen.vue";
import disclaimer from "./components/Disclaimer.vue";
import collectionDayLegend from "./components/CollectionDayLegend.vue";
import topicTiledLayers from "./components/TopicTiledLayers.vue";
import deploymentType from "./components/DeploymentType.vue";
import plowDeploymentLegend from "./components/PlowDeploymentLegend.vue";
import trashStatus from "./components/TrashStatus.vue";
import sweepLegend from "./components/SweepLegend.vue";

const customComps = {
  helpInstructions: instructions,
  SplashScreen: splashScreen,
  Disclaimer: disclaimer,
  CollectionDayLegend: collectionDayLegend,
  TopicTiledLayers: topicTiledLayers,
  DeploymentType: deploymentType,
  PlowDeploymentLegend: plowDeploymentLegend,
  TrashStatus: trashStatus,
  SweepLegend: sweepLegend,
};

var BASE_CONFIG_URL =
  "https://cdn.jsdelivr.net/gh/cityofphiladelphia/layerboard-default-base-config@30de220324fe788e261d18eefb52ddb57d32dd6e/config.js";

var GATEKEEPER_KEY = process.env.VUE_APP_GATEKEEPER_KEY;

let pictApiKey, pictSecretKey;
const host = window.location.hostname;
if (host === "topicsmap-dev.s3-website-us-east-1.amazonaws.com") {
  pictApiKey = process.env.VUE_APP_DEV_PICTOMETRY_API_KEY;
  pictSecretKey = process.env.VUE_APP_DEV_PICTOMETRY_SECRET_KEY;
} else {
  pictApiKey = process.env.VUE_APP_PICTOMETRY_API_KEY;
  pictSecretKey = process.env.VUE_APP_PICTOMETRY_SECRET_KEY;
}

var WEBMAP_ID =
  host === "streetsmartphl.phila.gov"
    ? "bf08ea4ce7194f68934a7150567151ae" //Production
    : "bf08ea4ce7194f68934a7150567151ae"; //Staging

const notShowAgain = localStorage.notShowAgain == "true" ? true : false;

const popoverConfig = {
  options: {
    height: "100%",
    background: "light",
    closeButton: false,
    components: [
      {
        type: "SplashScreen",
      },
    ],
  },
};

const initialPopover = notShowAgain ? null : popoverConfig;

layerboard(
  {
    // splashScreen,
    resetDataOnGeocode: false,
    labelFields: {
      "Collection Day Boundary - Intermediate": "COLLDAY",
    },
    customComps,
    footerContent: {
      components: [
        {
          type: "Anchor",
          options: {
            text: "City of Philadelphia",
            target: "blank",
            customStyle: { color: "white", "border-bottom": "0px" },
            href: "//www.phila.gov",
          },
        },
        {
          type: "PopoverLink",
          options: {
            height: "92%",
            customStyle: { color: "white", "border-bottom": "0px" },
            components: [
              {
                type: "helpInstructions",
              },
            ],
          },
          slots: {
            shouldShowValue: false,
            value: "How to use",
          },
        },
        {
          type: "Anchor",
          options: {
            text: "Feedback",
            target: "blank",
            customStyle: { color: "white", "border-bottom": "0px" },
            href: "//phila.formstack.com/forms/streetsmartphl_feedback",
          },
        },
      ],
    },
    bundled: true,
    layerFilter: false,
    router: {
      enabled: false,
    },
    geolocation: {
      enabled: true,
      icon: ["far", "dot-circle"],
      buttonPosition: "bottomleft",
    },
    addressInput: {
      width: 415,
      mapWidth: 300,
      position: "right",
      autocompleteEnabled: false,
      autocompleteMax: 15,
      placeholder: "Search for an address",
    },
    map: {
      defaultBasemap: "pwd",
      defaultIdentifyFeature: "address-marker",
      imagery: {
        enabled: true,
      },
      initialImagery: "imagery2022",
      historicBasemaps: {
        enabled: false,
      },
      center: [-75.16347348690034, 39.952562122622254],
      clickToIdentifyFeatures: true,
      containerClass: "map-container",
      basemapToggleControlPosition: "bottomleft",
      basemapSelectControlPosition: "bottomfurtherleft",
      measureControlEnabled: false,
      zoomControlPosition: "bottomleft",
      tiledLayers: {
        collectionDay: {
          url: "https://tiles.arcgis.com/tiles/fLeGjb7u4uXqeF9q/arcgis/rest/services/PickupPHL_CollectionBands/MapServer",
          zIndex: 5,
        },
        plowTreatedStreetsStatus: {
          url: "https://plowphl-services.phila.gov/arcweb/rest/services/Projects/TreatedStatus/MapServer",
          zIndex: 5,
        },
        plowNotTreatedStreets: {
          url: "https://plowphl-services.phila.gov/arcweb/rest/services/Projects/NotTreatedCity/MapServer",
          zIndex: 5,
        },
        plowConditional: {
          url: "https://plowphl-services.phila.gov/arcweb/rest/services/Projects/NotTreatedCond/MapServer",
          zIndex: 5,
        },
        plowHighways: {
          url: "https://plowphl-services.phila.gov/arcweb/rest/services/Projects/NotTreatedHigh/MapServer",
          zIndex: 5,
        },
      },
    },
    cyclomedia: {
      enabled: true,
      //orientation: "horizontal",
      buttonPosition: "bottomleft",
      measurementAllowed: false,
      popoutAble: true,
      recordingsUrl: "https://atlas.cyclomedia.com/Recordings/wfs",
      username: process.env.VUE_APP_CYCLOMEDIA_USERNAME,
      password: process.env.VUE_APP_CYCLOMEDIA_PASSWORD,
      apiKey: process.env.VUE_APP_CYCLOMEDIA_API_KEY,
    },
    pictometry: {
      enabled: false,
      iframeId: "pictometry-ipa",
      apiKey: pictApiKey,
      secretKey: pictSecretKey,
    },
    gatekeeperKey: GATEKEEPER_KEY,
    baseConfig: BASE_CONFIG_URL,
    webmapId: WEBMAP_ID,
    defaultPanel: "topics",
    dataSources: {
      weekPave,
      weekMill,
      notices,
      storms,
      trashDay,
    },
    topics: [pickup, permit, pave, plow, sweep],
    initialPopover,
    defaultTopic: null,
    components: [
      {
        type: "infoBox",
        slots: {
          text: "StreetSmartPHL is your portal for viewing information about Streets Department activities. \
            Click to open any of the topics below. Select features on the map for detailed information.",
        },
        options: {
          italic: true,
        },
      },
      {
        type: "topic-set",
        options: {
          plusMinus: true,
        },
      },
    ],
    modals: ["help"],
  }
  //obj
);
