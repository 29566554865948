const host = window.location.hostname;

let stormApiUrl =
  host === "streetsmartphl.phila.gov"
    ? "https://stsweb.phila.gov/StreetsCityWorks/api/Storm/getStorm" //Production
    : "https://stswebstaging.phila.gov/StreetsCityWorks/api/Storm/getStorm"; //Staging

export default {
  id: "storms",
  url: stormApiUrl,
  dependent: "none",
  type: "http-get",
  options: {},
  parameters: {
    targetField: 1,
    sourceValue: 1,
  },
  success(data) {
    return data;
  },
  error(err) {
    console.log("Storm error:", err);
  },
};
