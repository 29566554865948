<template>
  <div class="mains">
    <div class="text-message">
      <span v-html="message"></span
      ><i class="fas fa-exclamation-circle fa-1x"></i>
    </div>
  </div>
</template>

<script>
import TopicComponent from "@phila/vue-comps/src/components/TopicComponent.vue";

export default {
  name: "Deployment-Type",
  mixins: [TopicComponent],
  props: ["deployType"],
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {},
  computed: {
    message() {
      if (this.$props.options) {
        return this.evaluateSlot(this.$props.options.deployType) || "";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.text-message {
  float: right;
  width: 100%;
  height: 45px;
  background-color: #fff8d8;
  margin: auto;
  padding: 10px;
  font-size: 15px;
  color: #4a4a4a;
  font-family: "Montserrat";
  font-weight: 550;
  border-left: 5px solid #ffed97;
}

a:link,
a:visited,
a:hover,
a:active {
  color: #4a4a4a;
}
.mains {
  padding-bottom: 60px;
}
</style>
